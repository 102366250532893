





















import { Component, Vue } from 'vue-property-decorator'
import __ from '@/shared/helpers/__'
import PageHeader from '@/admin/components/PageHeader.vue'
import DataTable from '@/shared/components/data-table/DataTable.vue'
import DataTableBase from '@/shared/classes/components/data-table/data-table'
import DataTableHeader from '@/shared/classes/components/data-table/data-table-header'
import EditAction from '@/shared/classes/components/data-table/default-actions/edit-action'
import DeleteAction from '@/shared/classes/components/data-table/default-actions/delete-action'
import { AdminRoutes } from '@/shared/router/admin'
import { GlobalActions } from '@/shared/store/global/global.actions'
import AreYouSureDialog from '@/shared/components/dialogs/AreYouSureDialog.vue'
import { getNodeApiUrlByVersion } from "@/config";
import HseRiskTemplate from "@/shared/modules/hse-risk-templates/hse-risk-templates.model";
import HseRiskTemplateService from "@/shared/modules/hse-risk-templates/hse-risk-templates.service";

@Component({
  components: { DataTable, PageHeader },
  methods: { __ }
})
export default class RiskTemplatesIndex extends Vue {
  table: DataTableBase | null = null

  created() {
    this.table = new DataTableBase()
        .setModel(HseRiskTemplate)
        .setEndpoint(`${getNodeApiUrlByVersion()}/hse-risk-templates`)
        .setGroupBy('hseRiskCategoryName')
        .setGroupDesc(false)
        .setPaginationNotVisible(true)
        .setHeaders([
          new DataTableHeader()
              .setKey('name')
              .setSortable(false)
              .setText(__('admin.views.hse.risk-templates.index.table.columns.name')),
          new DataTableHeader()
              .setKey('is_visible')
              .setSortable(false)
              .setValueParser((value: any, item: HseRiskTemplate) => {
                if (item.isVisible) {
                  return __('admin.views.hse.risk-templates.index.table.columns.visible-option')
                }

                return __('admin.views.hse.risk-templates.index.table.columns.invisible-option')
              })
              .setText(__('admin.views.hse.risk-templates.index.table.columns.is-visible')),
        ])
        .setActions([
          new EditAction(),
          new DeleteAction()
              .setAction((item: HseRiskTemplate) => this.$store.dispatch(GlobalActions.showDialog, {
                show: true,
                component: AreYouSureDialog,
                meta: {
                  title: __('admin.views.hse.risk-templates.index.table.actions.delete.title'),
                  text: __('admin.views.hse.risk-templates.index.table.actions.delete.text', { name: item.name }),
                  onYes: () => HseRiskTemplateService.destroy(item.uuid)
                      .then(() => this.table && this.table.removeItem(item))
                }
              }))
        ])
  }

  navigateToCreate(): void {
    this.$router.push({ name: AdminRoutes.hseRiskTemplatesCreate })
  }

  get tableRef(): any {
    return this.$refs.table
  }
}
